define('ember-text-mask-addons/components/number-mask', ['exports', 'ember-text-mask/components/masked-input', 'ember-text-mask-addons'], function (exports, _maskedInput, _emberTextMaskAddons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function _createNumberMask(...args) {
    return Ember.computed(...args, function () {
      return this.createNumberMask(this.getProperties(...args));
    });
  }

  exports.default = _maskedInput.default.extend({
    createNumberMask: _emberTextMaskAddons.createNumberMask,
    mask: _createNumberMask('prefix', 'suffix', 'includeThousandsSeparator', 'thousandsSeparatorSymbol', 'allowDecimal', 'decimalSymbol', 'decimalLimit', 'requireDecimal', 'allowNegative', 'allowLeadingZeroes', 'integerLimit')
  });
});