define('ember-text-mask-addons/components/auto-corrected-date-pipe', ['exports', 'ember-text-mask/components/masked-input', 'ember-text-mask-addons'], function (exports, _maskedInput, _emberTextMaskAddons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const dateFormatChars = ['d', 'm', 'y'];
  const defaultDateFormat = 'mm/dd/yyyy';

  exports.default = _maskedInput.default.extend({

    dateFormat: defaultDateFormat,
    keepCharPositions: true,
    placeholderChar: '\u2000',

    createAutoCorrectedDatePipe: _emberTextMaskAddons.createAutoCorrectedDatePipe,

    /*
      Return an object with both mask and pipe.
    */
    mask: Ember.computed('dateFormat', function () {
      let dateFormat = this.get('dateFormat') || defaultDateFormat;
      return {
        mask: dateFormat.split('').map(char => dateFormatChars.indexOf(char.toLowerCase()) === -1 ? char : /\d/),
        pipe: this.createAutoCorrectedDatePipe(dateFormat)
      };
    })
  });
});