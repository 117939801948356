define("ember-select/utils/tree", ["exports", "@ember/array", "@ember/object", "@ember/utils", "@ember/object/proxy"], function (_exports, _array, _object, _utils, _proxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildFlatList = buildFlatList;
  _exports.buildTree = buildTree;
  _exports.getDescendents = getDescendents;

  /* Build a tree (nested objects) from a plain array
   * using `id` and `parentId` as references for the
   * relationships. The `name` property is expected
   * for rendering. Optionally, `valueKey` can be
   * passed for `id` and `labelKey` for `name`.
   * If the model is flat, it will return a list.
   */
  function buildTree(model, options) {
    let tree = {};
    let roots = (0, _array.A)();

    if ((0, _utils.isEmpty)(model)) {
      return roots;
    }

    let element = model[0] || (0, _object.get)(model, 'firstObject');

    if (typeof element !== 'object') {
      // Not a model of objects, hence it should be a flat list
      return buildFlatList(model);
    } // Add all nodes to tree


    model.forEach(node => {
      let child = {
        content: node,
        children: (0, _array.A)(),
        isSelected: false,
        isVisible: true
      }; // Alternative name for `id`

      if (options.valueKey) {
        child.id = (0, _object.get)(node, options.valueKey);
      } // Alternative name for `name`


      if (options.labelKey) {
        child.name = (0, _object.get)(node, options.labelKey);
      } // Decide if node is expanded


      if ((0, _utils.isPresent)(options.isExpanded)) {
        child.isExpanded = options.isExpanded;
      } // Proxy options to keep model intact


      tree[(0, _object.get)(child, 'id')] = _proxy.default.create(child);
    }); // Connect all children to their parent

    model.forEach(node => {
      let child = tree[(0, _object.get)(node, options.valueKey || 'id')];
      let parent = (0, _object.get)(node, 'parentId');

      if ((0, _utils.isEmpty)(parent)) {
        roots.push(child);
      } else {
        tree[parent].children.push(child);
      }
    });
    return roots;
  } // Builds a list of proxies from a model of values


  function buildFlatList(model) {
    let list = model.map(node => _proxy.default.create({
      id: node,
      name: node,
      isSelected: false,
      isVisible: true
    }));
    return (0, _array.A)(list);
  }

  function getDescendents(tree) {
    let descendents = (0, _array.A)();
    tree.forEach(node => {
      descendents.pushObject(node);
      descendents.pushObjects(getDescendents(node.children));
    });
    return descendents;
  }
});